.fa.fa-refresh {
  color: #69c9ca;
}

.fa.fa-refresh.white {
  color: #fff;
}

.fa-spin {
  font-size: 25px;
  display: block;
}

.fa-spin {
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
