.class-details-player {
  width: 100%;

  &.with-margin {
    @include custom-media-query(0px, 1199px) {
      padding-top: 70px;
    }

    @include for-desktop-up {
      padding-top: 79px;
    }
  }

  .player-poster {
    background-color: #1f2227;
  }

  [data-player] {
    @include for-phone-only {
      min-height: 250px !important;
      height: 100%;
    }

    @include for-tablet-only {
      min-height: 350px !important;
      height: 100%;
    }

    @include for-desktop-up {
      min-height: 500px !important;
      height: 100%;
    }

    width: 100% !important;
  }
}

.modal-player {
  width: 100%;

  .player-poster {
    background-color: #1f2227;
  }

  [data-player] {
    @include for-phone-only {
      min-height: 250px !important;
      height: 100%;
    }

    @include for-tablet-only {
      min-height: 350px !important;
      height: 100%;
    }

    @include for-desktop-up {
      min-height: 500px !important;
      height: 100%;
    }

    width: 100% !important;
  }
}

.player-inside-page {
  top: 2px;
  right: 0;
  z-index: -1;

  display: flex;
  justify-content: center;

  @include for-tablet-up {
    right: 15px;
  }

  [data-player] {
    max-width: 568px !important;
    height: 330px !important;
    width: 100% !important;
    @include for-phone-only {
      height: 220px !important;
    }

    @include for-tablet-only {
      right: -25px;
    }

    @include for-desktop-up {
      float: right !important;
    }
  }
}
