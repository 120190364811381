.form-control {
  background-color: #2C2A2C;
  border: 1px solid #333;
  outline: none;
  box-shadow:none;
  color: #FFF;
  margin-bottom: 5px;

  &:focus {
    border-color: rgba(25, 162, 169, 0.8);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(25, 162, 169, 0.6);
    outline: 0 none;
  }

  &.ng-touched.ng-invalid {
    border-color: #CA3523;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(#CA3523, 0.6);
  }
}

.text-danger {
  color: #CA3523;
  font-size: 13px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('./../../assets/img/icons/dropdown.png') right / 20px no-repeat #2C2A2C !important;
  padding-right: 20px;
}

.panel {
  border-radius: 5px;
  // background-color: #191919;
  background-color: rgba(25, 25, 25,0.7);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  width: 457px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;

  @include for-phone-only {
    // width: 100%;
    width: 90%;
    margin-top: 0px;
  }

}

.validator-wrapper {
  position: relative;
  padding: 0;
  margin: 0;

  .validator-loading {
    position: absolute;
    top: 8px;
    right: 8px;

    height: 17px;
    width: 17px;
    border-radius: 50%;

    border: 2px solid #f3f3f3;
    border-top: 2px solid rgba(0,0,0,0);
    animation: spin 2s linear infinite;

    &.dark-loading {
      border: 2px solid #191919;
      border-top: 2px solid #f3f3f3;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
}

.error-container {
  height: 29px;
  line-height: 1.0;
}
