@import url('https://fonts.googleapis.com/css?family=Titillium+Web');
@import url('https://fonts.googleapis.com/css?family=Titillium+Web:200,200i,600,600i,800, 800i');


html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px
}

body {
  -webkit-font-smoothing: subpixel-antialiased; // Fix font-weight on mac
  font-family: 'Titillium Web', sans-serif;
  color:#FFF;
}

.titulo {
  font-size: 40px;
  font-weight: 800;

  @include for-desktop-large-up {
    font-size: 6rem;
  }

  @include for-desktop-only {
    font-size: 4.5rem;
    font-weight: bold;
  }

  @include for-tablet-only {
    font-size: 4rem;
    font-weight: bold;
  }
}

.subtitulo {
  font-size: 30px;
  font-weight: 800;
  line-height: 130%;
  margin-top: 36px;
  padding: 0px 3rem 0px 3rem;

  @include for-tablet-up {
    font-size: 56px;
    margin-bottom: 32px;
    padding: 0;
  }
}

p {
  font-size: 2rem;
  line-height: 140%;

  @include for-desktop-up {
    font-size: 2.6rem;
    &.small { font-size: 1.8rem; }
  }

  @include for-desktop-only {
    font-size: 2.3rem;
    &.small { font-size: 1.6rem; }
  }

  @include for-phone-and-tablet {
    font-size: 2rem;
    &.small { font-size: 1.6rem; }
  }
}

.title {
  font-size: 3.5rem;
  font-weight: 800;

  @include for-desktop-only {
    font-size: 4.5rem;
    font-weight: bold;
  }

  @include for-tablet-only {
    font-size: 4rem;
    font-weight: bold;
  }
}

.subtitle {
  font-size: 3rem;
  font-weight: 800;

  @include for-desktop-only {
    font-size: 4rem;
    font-weight: bold;
  }

  @include for-tablet-only {
    font-size: 3.5rem;
    font-weight: bold;
  }
}

.text {
  font-size: 2rem;
  line-height: 140%;

  @include for-desktop-up {
    font-size: 2.6rem;
    &.small { font-size: 1.8rem; }
  }

  @include for-desktop-only {
    font-size: 2.3rem;
    &.small { font-size: 1.6rem; }
  }

  @include for-phone-and-tablet {
    font-size: 2rem;
    &.small { font-size: 1.6rem; }
  }
}
