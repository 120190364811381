#toast-container {
  .toast {
    box-shadow: none !important;
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    color: #FFFFFF;
    opacity: 0.8;

    &:hover {
      box-shadow: none !important;
    }

  }

  .toast-success {
    background-color: #339899;
  }

}
