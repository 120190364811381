@import '~scss/variables/colors';

.store-button {
  display:inline-block;
  margin: 1rem;
}

.btn-btfit {
  color: #FFF;
  font-weight: 600;
  font-size: 1.6rem;
  background-color:$color-primary;
  border: 1px solid $color-primary-light;

  &:hover, &:focus {
    color: #FFF;
    background-color: $color-primary-dark;
    text-decoration: none;
    outline: none;
  }
}

.btn-default {
  background-color: #2C2A2C;
  border: 1px solid #333;
  outline: none;
  box-shadow:none;
  color: #FFF;

  &:hover, &:focus, &:active, &:focus:active {
    color: #FFF;
    background-color: lighten(#2C2A2C, 5%) !important;
    border: 1px solid #333;
    outline: none !important;
  }
}

.btn-submit {
  background-color: $color-primary;
  border: 1px solid $color-primary-light;
  color: #FFF;
  outline: none !important;

  &:hover, &:focus, &:active {
    color: #FFF;
    background-color: $color-primary-dark;
    border: 1px solid $color-primary-light;
    outline: none !important;
  }

}

.btn-nbg {

  &, &:link, &:visited {
    color: #fff;
    font-size: 6rem;
    font-weight: 800;
    padding: .5rem 2rem;
    background: none;
    border: none;
    
    @include for-phone-only {
      font-size: 2.8rem;
    }

    @include for-tablet-only {
      font-size: 4.8rem;
    }
  }

  &:hover, &:active {
    text-decoration: none;
  }

  &.active, &:active {
    border-bottom: 4px solid $color-primary;
  }

  &:focus {
    outline: none;
    text-decoration: none;
  }

}

.btn-link {
  font-size: 2rem;
  color: $color-secondary-dark;
  text-decoration: underline;  
}


// modifiers

.btn--large {
  font-size: 2.4rem;
  padding: 1rem 4rem;
}

.btn--small {
  font-size: 1.4rem;
  padding: .5rem 2rem;
}

%generic-live-class-button {
  display: flex;
  text-align: center;
  background-color: transparent;
  text-align: center;
  width: 138px;
  justify-content: space-evenly;
  align-items: center;
  height: 36px;
  padding: 5px 10px 5px 8px;
  border-radius: 18px;
}

%generic-live-class-button-before {
  content: "";
  width: 16px;
  height: 16px;
  margin-right: 6px;
  object-fit: contain;
  background-size: contain;
}

.button-favorite {
  @extend %generic-live-class-button;
  border: 1.5px solid $color-accent-l1;
  color: $color-accent-l1;
}

.button-favorite::before {
  @extend %generic-live-class-button-before;
  background: url('../../assets/img/icons/ic-live-fav-3.svg');
}

.button-unfavorite {
  @extend %generic-live-class-button;
  border: 1.5px solid $color-accent-l1;
  color: $color-accent-l1;
}

.button-unfavorite::before {
  @extend %generic-live-class-button-before;
  background: url('../../assets/img/icons/ic-live-fav-4.svg');
}

.button-play {
  @extend %generic-live-class-button;
  border: 1.5px solid $color-primary-light;
}

.button-play::before {
  @extend %generic-live-class-button-before;
  background: url('../../assets/img/icons/ic-live-play-3.svg');
}

