.blur-container {
  border-radius: 10px;
  background-color: rgba(25, 25, 25, 0.7);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.divider {
  border-top: 1px solid rgba($color-grey-dark, 0.3);
}

.divider-vertical {
  border-right: 1px solid rgba($color-grey-dark, 0.3);
}

.text-underline {
  text-decoration: underline;
}

.bg-body {
  background-color: #30343b;
}

.d-flex {
  display: flex;
} 

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.font-bold {
  font-weight: bold;
}

.font-semibold {
  font-weight: 600;
}

.line {
  width: 100%;
  height: 1px;
  background-color: $gray-06;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mb-16 {
  margin-bottom: 16px;
}

.color-white {
  color: #fff;
}