.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
  background-color: $color-primary-light;
  font-weight: 600;
}

.nav-pills>li>a {
  color: #fff;
  font-weight: 600;

  &:focus, &:hover {
    background-color: rgba(0, 0, 0, .1);
  }
}