$space-sm: 2rem;
$space-md: 4rem;
$space-lg: 8rem;

.margin-sm {
  margin-bottom: $space-sm;
}

.margin-md {
  margin-bottom: $space-md;
}

.margin-lg {
  margin-bottom: $space-lg;
}
