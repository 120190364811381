$color-primary: #097980;
$color-primary-dark: #06686E;
$color-primary-light: #00B9C1;
$color-primary-light-02: #00cccc;

$color-primary-d1: #065e64;

$color-secondary: #282B30;
$color-secondary-light: #333333;
$color-secondary-dark: #191A1E;

$color-grey-darker: #919192;
$color-grey-dark: #aeaeae;
$color-grey: #E9EDF0;
$color-grey-light: #f2f2f2;
$color-grey-lighter: #F7F7F7;
$color-secondary-gray-07: #202328;

$color-white-60: rgba(255, 255, 255, 0.6);

$color-background-dark: #1F2227;

$color-accent-l1: #8d69ff;
$gray-border: #5f5f5f;
// new colors - purchase page
$gray-01: #f7f7f7;
$gray-02: #e8e8e8;
$gray-03: #d3d3d3;
$gray-04: #bcbdbe;
$gray-05: #9a9a9a;
$gray-06: #4e565f;
$gray-07: #2b2f35;
$gray-08: #191a1e;