.personal-carousel {

  .carousel-container .slick-list {

    .slick-track{
      margin: 40px 0px 40px 0px;
    }

  }

  .slick-prev, .slick-next {
    color: #fff;
    font-size: 0;
    background: $color-primary-light !important;
    height: 34px;
    width: 34px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    padding: 0;
    border: none;
    outline: none;
    border-radius: 50%;
    cursor: pointer;

    &:before {
      font-size: 28px;
      font-weight: normal;
      line-height: 10px;
    }

  }

  .slick-prev {

    &:before{
      content: "<";
      margin-left:-2px;
    }

    @include for-desktop-up {
      left: calc(50% - 120px);
      z-index: 1;
    }

    @include for-phone-and-tablet {
      left: 0%;
      z-index: 1;
    }

  }

  .slick-next {

    &:before{
      content: ">";
      margin-left: 5px;
    }

    @include for-desktop-up {
      left: calc(50% + 90px);
    }

    @include for-phone-and-tablet {
      right: 1%;
      z-index: 1;
    }

  }

  .slider-for {
    padding-top: 20px;
  }

  .slider-for > .slick-list > .slick-track {
    margin: 20px 0 40px 0;
  }

  .slick-slide {

    display: block;

    .carousel-image {
      display: block;
      width: 100%;
      border-radius: 50%;
      opacity: .2;
      -moz-transition: all 300ms ease;
      -o-transition: all 300ms ease;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
      z-index: 1;
    }

  }

  .slick-center {

    z-index: 2;
    position: relative;

    .carousel-image {
      border-radius: 50%;
      background-color: none;
      border: 8px solid $color-primary-light;
      -moz-transform: scale(1.4);
      -ms-transform: scale(1.4);
      -o-transform: scale(1.4);
      -webkit-transform: scale(1.4);
      transform: scale(1.4);
      opacity: 1;
    }

    .carousel-description {
      opacity: 1;
    }

  }
}

.simple-carousel {

  .slider {
    margin-top: 78px !important;

    @include custom-media-query(0px, 1199px) {
      margin-top: 70px !important;
    }

  }

  .slick-prev, .slick-next {
    position: absolute;
    display: block;
    height: 34px;
    width: 34px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    color: #f2f2f2;
    background: $color-primary-light;
    padding: 0;
    border: none;
    outline: none;
    border-radius: 50%;
    top: 50%;
    z-index: 1;

    &:before {
      font-size: 28px;
      line-height: 13px;
      font-weight: bolder;
    }

  }

  .slick-prev {

    left: 0;
    margin-left: 20px;

    &:before{
      content: "<";
      margin-left:-2px;
    }

  }

  .slick-next {

    right: 0;
    margin-right: 20px;

    &:before{
      content: ">";
      margin-left:3px;
    }

  }

  .slick-dots {
    position: absolute;
    bottom: 5%;
    display: block;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
      position: relative;
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0px 10px;
      padding: 0;
      cursor: pointer;

      &.slick-active {
        button {
          background-color: #5EBABB;
        }
      }

      button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 10px;
        height: 10px;
        padding: 5px;
        cursor: pointer;
        color: #000;
        border: 0;
        border-radius: 50%;
        outline: none;
        background: #FFF;

      }
    }
  }
}
