.TitilliumWeb-BoldItalic {
    font-family: 'TitilliumWeb-BoldItalic', sans-serif;
}

.TitilliumWeb-SemiBold {
    font-family: 'TitilliumWeb-SemiBold', sans-serif;
}

.TitilliumWeb-Italic {
    font-family: 'TitilliumWeb-Italic', sans-serif;
}

.TitilliumWeb-ExtraLightItalic {
    font-family: 'TitilliumWeb-ExtraLightItalic', sans-serif;
}

.TitilliumWeb-ExtraLight {
    font-family: 'TitilliumWeb-ExtraLight', sans-serif;
}

.TitilliumWeb-Light {
    font-family: 'TitilliumWeb-Light', sans-serif;
}

.TitilliumWeb-Black {
    font-family: 'TitilliumWeb-Black', sans-serif;
}

.TitilliumWeb-SemiBoldItalic {
    font-family: 'TitilliumWeb-SemiBoldItalic', sans-serif;
}

.TitilliumWeb-Bold {
    font-family: 'TitilliumWeb-Bold', sans-serif;
}

.TitilliumWeb-LightItalic {
    font-family: 'TitilliumWeb-LightItalic', sans-serif;
}

.TitilliumWeb-Regular {
    font-family: 'TitilliumWeb-Regular', sans-serif;
}

.OpenSans-ExtraBoldItalic {
    font-family: 'OpenSans-ExtraBoldItalic';
}

.OpenSans-BoldItalic {
    font-family: 'OpenSans-BoldItalic';
}

.OpenSans-Regular {
    font-family: 'OpenSans-Regular';
}

.OpenSans-Bold {
    font-family: 'OpenSans-Bold', sans-serif;
}

.OpenSans-Light {
    font-family: 'OpenSans-Light';
}

.OpenSans-Italic {
    font-family: 'OpenSans-Italic';
}

.OpenSans-SemiBold {
    font-family: 'OpenSans-SemiBold';
}

.OpenSans-ExtraBold {
    font-family: 'OpenSans-ExtraBold';
}

.OpenSans-SemiBoldItalic {
    font-family: 'OpenSans-SemiBoldItalic';
}

.OpenSans-LightItalic {
    font-family: 'OpenSans-LightItalic';
}