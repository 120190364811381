$tablet-width: 768px;
$desktop-width: 992px;
$desktop-large-width: 1200px;

// |---------------- 767px | <---
@mixin for-phone-only {
  @media (max-width: $tablet-width - 1) { @content; }
}
// --> | 768px ----------------|
@mixin for-tablet-up {
  @media (min-width: $tablet-width) { @content; }
}
// --> | 768px ---------------- 991px | <--
@mixin for-tablet-only {
  @media (min-width: $tablet-width) and (max-width: $desktop-width - 1) { @content; }
}
// --> | 992px ----------------|
@mixin for-desktop-up {
  @media (min-width: $desktop-width) { @content; }
}
// --> | 992px ---------------- 1199px | <--
@mixin for-desktop-only {
  @media (min-width: $desktop-width) and (max-width: $desktop-large-width - 1) { @content; }
}
// --> | 1200px ----------------|
@mixin for-desktop-large-up {
  @media (min-width: $desktop-large-width) { @content; }
}

// |---------------- 991px | <---
@mixin for-phone-and-tablet {
  @include for-tablet-only {
    @content
  }
  @include for-phone-only {
    @content
  }
}

@mixin custom-media-query($min, $max) {
  @media (min-width: $min) and (max-width: $max) { @content; }
}
