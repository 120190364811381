// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$angular-btfit-primary: mat.define-palette(mat.$indigo-palette);
$angular-btfit-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$angular-btfit-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$angular-btfit-theme: mat.define-light-theme($angular-btfit-primary, $angular-btfit-accent, $angular-btfit-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($angular-btfit-theme);

// Workaround para o problema de import na ordem errada https://github.com/angular/angular-cli/issues/9475
@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

@import "variables/breakpoints";
@import "variables/colors";
@import "variables/spacing";
@import "variables/utilities";

@import "base/animations";
@import "base/typography";

@import "components/buttons";
@import "components/forms";
@import "components/toast";
@import "components/player";
@import "components/slick-carousel";
@import "components/modal";

@import "layout/body";

@import "overrides/fa";

@import "mixins/background";

// Google Fonts converted to TTF, WOFF, WOFF2, SVG and EOT
@import '../assets/scss/webfonts/titillium-web/stylesheet.css';
@import '../assets/scss/webfonts/open-sans/stylesheet.css';

@import '../assets/scss/text';

.price-highlighted {
  font-size: 35px;
  vertical-align: middle;
}

/* width */
::-webkit-scrollbar {
  border-radius: 8px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1b1e22;
  border-bottom-right-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #30343b;
  widows: 120px;
  background-clip: content-box;
  border: 2px solid transparent;
}

body {
  div, button, input, textarea {
    outline: none;
  }
}

.empty-state {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0 10px;
  @include for-tablet-up {
    padding: 0 94px;
  }
  .empty-state-title {
    color: #d3d3d3;
    font-family: "Titilium Web", sans-serif;
    font-size: 14px;
    @include for-tablet-up {
      font-size: 18px;
    }
  }
  .empty-state-image {
    padding-bottom: 8px;
  }
}

.fa-pro-icon {
  width: 10px;
  filter: invert(1);
}

#zmmtg-root {
  display: none;
}

.zm-btn.joinWindowBtn {
  background-color: $color-primary !important;
}

.mat-dialog-container {
  background-color: transparent;
  padding: 0 !important;
}